import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';

import variables from '../styles/variables';
import Layout from '../components/layout';
import { PageTitle, SectionTitle } from '../components/titles';
import Tag from '../components/tag';

const BlogList = styled.ol`
  margin: 0;
  list-style-type: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colorTextDark};
`;

const BlogPost = styled.li`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-left: 2rem;
  color: ${props => props.theme.colorText};
  background: linear-gradient(
    to right,
    ${props => props.theme.colorPrimaryDark},
    #000000
  );
  border-top: 1px solid ${props => props.theme.colorPrimaryLight};
  border-left: 1px solid ${props => props.theme.colorPrimaryLight};

  @media only screen and (min-width: calc(${variables.screenWidth} + 1px)) {
    :hover {
      padding-left: 1.1rem;
      border-left: 1rem solid ${props => props.theme.colorPrimaryLight};
    }
  }
`;

const Date = styled.div`
  font-size: 1.3rem;
`;

const TagContainer = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            tags
            slug
            publishedDate(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  `);

  return (
    <>
      <PageTitle>Blog</PageTitle>
      <BlogList>
        {data.allContentfulBlogPost.edges.map(edge => (
          <StyledLink key={edge.node.id} to={`/blog/${edge.node.slug}`}>
            <BlogPost>
              <SectionTitle>{edge.node.title}</SectionTitle>
              <Date>{edge.node.publishedDate}</Date>
              <TagContainer>
                {edge.node.tags.map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </TagContainer>
            </BlogPost>
          </StyledLink>
        ))}
      </BlogList>
    </>
  );
};

BlogPage.Layout = Layout;

export default BlogPage;
